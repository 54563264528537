import {
  ConfirmOrderParams,
  CreateOrderParams,
  GetOrderParms,
  PreviewOrderParams,
  UpdateOrderFromCustomerParams,
  PublicUpdateOrderParams,
  UpdateOrderParams,
  PublicUpadateCustomerParams,
  UpdateOrderPaymentStatusParams,
  PublicCreateCustomerParams,
} from "./interfaces/orders-customers.interface";
import { request } from "./utils";

const CreateOrder = (data: CreateOrderParams) => {
  return request("orders", "post", { data });
};

const PreviewOrder = (data: PreviewOrderParams) => {
  return request(`orders/preview/${data.id}?phone=${data.phone}`, "get");
};

const ConfirmOrder = (data: ConfirmOrderParams) => {
  return request("orders/confirm", "post", { data: { order: data.id } });
};

const GetOrder = (data: GetOrderParms) => {
  return request(`orders/${data.id}`, "get");
};

const UpdateOrder = (data: UpdateOrderParams) => {
  const orderId = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`orders/${orderId}`, "put", { data: dataCopy });
};

const UpdateOrderFromSeller = (data: PublicUpdateOrderParams) => {
  return request(`orders/${data.id}/seller`, "put", { data: data.data });
};

const UpdateOrderPaymentStatus = (data: UpdateOrderPaymentStatusParams) => {
  return request(`orders/${data.id}/payment`, "put", {});
};

const UpdateOrderFromCustomer = (data: PublicUpdateOrderParams) => {
  return request(`orders/${data.id}/customer?phone=${data.data.phone}`, "put", { data: data.data });
};

const CreateCustomerPublic = (data: PublicCreateCustomerParams) => {
  return request(`customers/public`, "post", { data });
};

const UpdateCustomerPublic = (data: PublicUpadateCustomerParams) => {
  const customerId = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`customers/${customerId}/public`, "put", { data: dataCopy });
};

export {
  CreateOrder,
  PreviewOrder,
  ConfirmOrder,
  GetOrder,
  UpdateOrder,
  UpdateOrderFromSeller,
  UpdateOrderFromCustomer,
  UpdateCustomerPublic,
  UpdateOrderPaymentStatus,
  CreateCustomerPublic,
};
